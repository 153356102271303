import { EventEmitter } from 'events';
import { debounce } from 'lodash-es';

const TABLET_BREAKPOINT = 768;
const LAPTOP_BREAKPOINT = 1024;
const LARGE_LAPTOP_BREAKPOINT = 1280;
const DESKTOP_BREAKPOINT = 1440;

const DEBOUNCE_DELAY = 100; // in ms

export default class Viewport extends EventEmitter {
	constructor() {
		super();
		this.resizeHandler();
		this.bind();
	}

	destroy() {
		this.unbind();
	}

	/**
	 * Return the viewport width.
	 *
	 * @return {number} The viewport width in px
	 */
	getViewportWidth() {
		return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	}

	/**
	 * Return the viewport height.
	 *
	 * @return {number} The viewport height in px
	 */
	getViewportHeight() {
		return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	}

	getViewportRatio() {
		return (this.currentWidth / this.currentHeight);
	}

	bind() {
		this.onResize = debounce(this.resizeHandler.bind(this), DEBOUNCE_DELAY);
		window.addEventListener('resize', this.onResize);
	}

	unbind() {
		if (! this.onResize) {
			return;
		}
		window.removeEventListener('resize', this.onResize);
		this.onResize = null;
	}

	resizeHandler() {
		this.currentWidth = this.getViewportWidth();
		this.currentHeight = this.getViewportHeight();
		this.emit('resize');
	}

	isMobile() {
		return this.currentWidth < TABLET_BREAKPOINT;
	}

	isTablet() {
		return this.currentWidth >= TABLET_BREAKPOINT && this.currentWidth < LAPTOP_BREAKPOINT;
	}

	isComputer() {
		return this.currentWidth >= LAPTOP_BREAKPOINT;
	}

	isLaptop() {
		return this.currentWidth >= LAPTOP_BREAKPOINT && this.currentWidth < LARGE_LAPTOP_BREAKPOINT;
	}

	isLargeLaptop() {
		return this.currentWidth >= LARGE_LAPTOP_BREAKPOINT && this.currentWidth < DESKTOP_BREAKPOINT;
	}

	isDesktop() {
		return this.currentWidth >= DESKTOP_BREAKPOINT;
	}
}

// I mean this is not good.
window.viewport_service = new Viewport();
