/**
 * Calculate the full size of the given Element including the margin.
 *
 * @param {Element} element The element to get the size
 * @returns {Object} An object with the `width` & `height` of the element.
 */
export default function ( element ) {
  const computedStyles = window.getComputedStyle( element );
  return {
    width: element.offsetWidth + parseFloat(computedStyles.marginLeft) + parseFloat(computedStyles.marginRight),
    height: element.offsetHeight + parseFloat(computedStyles.marginTop) + parseFloat(computedStyles.marginBottom)
  }
}
