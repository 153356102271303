import BaseView from '../../../js/base-view';

import elementSize from '../../../js/utils/element-size';

const SELECTOR_TOGGLE = '.expandable__toggle';
const SELECTOR_CONTENT = '.expandable__content';

export default class Expandable extends BaseView {

  init () {
		// Sub elements
		this.refs = {
			content: this.el.querySelector( SELECTOR_CONTENT ),
			items: null,
		}
		this.refs.items = Array.from(this.refs.content.children);

    // States
    this.state = {
			isHidden: (this.el.getAttribute('aria-hidden') === 'true'),
		};

    this.setMaxHeight();

    // Events
    this.on( 'click', SELECTOR_TOGGLE, this.toggle.bind(this) );
		this.on( 'resize', this.setMaxHeight.bind(this) );
  }

  toggle () {
    this.state.isHidden = !this.state.isHidden;
    this.el.setAttribute( 'aria-hidden', this.state.isHidden );
  }

  setMaxHeight () {
		const contentHeight = this.refs.items.reduce( (totalHeigh, child) => (totalHeigh + elementSize(child).height), 0 );
    this.refs.content.style.maxHeight = `${contentHeight}px`;
  }
}
