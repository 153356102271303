/* eslint-disable new-cap */

import Expandable from './1-javascript/expandable';

const COMPONENTS_TO_LOAD = [
	{ view: Expandable, name: 'Expandable', selector: '.expandable' },
];

/**
 * Load all the components.
 * All the component are builded using the same interface, so...
 * the .bind method does the magic for everyone.
 */
const LOADED_COMPONENTS = {};

COMPONENTS_TO_LOAD.forEach(({ selector, view, name }) => {
	const elements = Array.from(document.querySelectorAll(selector));

	// Bail earl if element exists
	if (elements.length) {
		elements.forEach((el, idx) => {
			try {
				const v = new view(el).init();
				LOADED_COMPONENTS[ `${ name }_${ idx }` ] = v;
			}
			catch (error) {
				console.error('💥 Could not init view:', name, '⬇️');
				console.error(error);
			}
		});
	}
});
